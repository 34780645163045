<template>
  <div class="realname">
    <div class="content-top">
      <el-steps :active="active" align-center>
        <el-step title="填写企业信息"></el-step>
<!--        <el-step title="打款验证"></el-step>-->
        <el-step title="完成认证"></el-step>
      </el-steps>
    </div>
    <!-- 填写企业信息 -->
    <div class="enterprise" v-if="active == 1">
      <el-form
        :model="qyruleForm"
        :rules="qyrules"
        ref="qyruleForm"
        label-width="140px"
        class="demo-qyruleForm"
      >
        <el-form-item label="企业名称:" prop="name"
          ><el-input
            v-model="qyruleForm.name"
            placeholder="请输入企业名称"
          ></el-input
        ></el-form-item>
        <el-form-item label="法人姓名:" prop="username"
          ><el-input
            v-model="qyruleForm.username"
            placeholder="请输入法人姓名"
          ></el-input
        ></el-form-item>
        <el-form-item label="统一社会信用代码:" prop="code"
          ><el-input
            v-model="qyruleForm.code"
            placeholder="请输入统一社会信用代码"
          ></el-input
        ></el-form-item>

        <el-form-item label="企业地址:" prop="address"
          ><el-input
            v-model="qyruleForm.address"
            placeholder="请设置企业地址"
            @click.native="workDialogVisible = true"
          ></el-input
        ></el-form-item>
        <el-form-item label="上传营业执照:" prop="businessurl">
          <el-upload
            action="https://freemen.work/user/file/upload/"
            list-type="picture-card"
            :limit="1"
            :on-success="handleSuccess"
            :on-remove="handleRemove"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <el-dialog :visible.sync="dialogVisible">
            <img
              width="100%"
              :src="
                'https://freemen.work/user/file/view/' + qyruleForm.businessurl
              "
              alt=""
            />
          </el-dialog>
          <div class="imgts font14">只能上传jpg/png格式文件</div>
        </el-form-item>
        <el-form-item
          ><el-button
            type="primary"
            class="d-btn-orange"
            @click="submitForm('qyruleForm')"
            >下一步</el-button
          ></el-form-item
        >
      </el-form>
    </div>
    <!-- 打款验证 -->
    <div class="payment" v-if="active == 2">
      <div class="tabble font14">
        <div class="t-item">
          <div class="to-left">汇款金额：</div>
          <div class="to-right">
            <div class="">￥{{ payMoney }}</div>
            <div class="ts_text">(金额随机，请按照指定金额以作验证)</div>
          </div>
        </div>
        <div class="t-item">
          <div class="to-left">汇款账号：</div>
          <div class="to-right">{{ bankNum }}</div>
        </div>
        <div class="t-item">
          <div class="to-left">开户银行：</div>
          <div class="to-right">{{ bankAdress }}</div>
        </div>
        <div class="t-item">
          <div class="to-left">企业名称：</div>
          <div class="to-right">杭州玛亚科技有限公司</div>
        </div>
        <div class="t-item">
          <div class="to-left">验证状态：</div>
          <div class="to-right">
            <!-- <div class="y-ing">审核中</div> -->
            <!-- <div class="y-success">验证成功</div> -->
            <div class="y-fail" v-if="statusChecker.payStatus == -1 && statusChecker.cmpyAuthStatus === -1">已拒绝</div>
            <div class="y-fail" v-else-if="statusChecker.payStatus == -1 && statusChecker.cmpyAuthStatus === 0">资质审核未通过</div>
            <div class="y-fail" v-else-if="statusChecker.payStatus == 0 && statusChecker.cmpyAuthStatus === 0">审核中</div>
            <div class="y-fail" v-else-if="statusChecker.cmpyAuthStatus === 0">企业资质审核中</div>
            <div class="y-fail" v-else-if="statusChecker.payStatus <= 0">打款未认证</div>
            <div class="y-fail" v-else-if="statusChecker.payStatus == 1" style="font-weight: bold; color: #409eff">
              审核通过
            </div>
            <div class="y-fail" v-else >审核中</div>
          </div>
        </div>
        <div class="t-item" v-if="statusChecker.payStatus == -1 && statusChecker.cmpyAuthStatus === -1">
          <div class="to-left">拒绝原因：</div>
          <div class="to-right">
            <!-- <div class="y-ing">审核中</div> -->
            <!-- <div class="y-success">验证成功</div> -->
            <div class="y-fail" style="color: red">
              {{ reason }}
              <span v-if="statusChecker.cmpyAuthStatus !== 1">( 如已经打款则无需再次打款 )</span>
            </div>
          </div>
        </div>
        <div class="t-item" v-if="statusChecker.payStatus == -1 && statusChecker.cmpyAuthStatus === 0 && (reason !== '' || reason !== null)">
          <div class="to-left">上次被拒原因：</div>
          <div class="to-right">
            <div class="y-fail">
              {{ reason }}
              <span v-if="statusChecker.cmpyAuthStatus !== 1">( 如已经打款则无需再次打款 )</span>
            </div>
          </div>
        </div>
      </div>
      <div class="pay_tishi" v-if="statusChecker.payStatus != 1">
        <div class="p-img font14">
          <i class="el-icon-warning"></i>
        </div>
        <div class="p-cont font14">
          为了验证您公司账户真实性，您需要往我司汇款上方金额，汇款完成后点击下方“我已汇款”
          按钮我司将在1-3个工作日内进行审核。
        </div>
      </div>
      <!-- <div class="fail-reason font14" v-if="payStatus != 1">
        打款失败提示：未收到打款金额，请按照指定金额打款验证，汇款完成后，点击“我已汇款”按钮我司将在
        1-3个工作日内进行审核。
      </div> -->
      <!--			<div class="succes-text">-->
      <!--				<div class="font14">恭喜您通过已通过平台打款验证</div>-->
      <!--				<div class="st-btn font14">-->
      <!--					<el-button type="primary" class="pb-btn2" @click="$router.push('induction')">去发布职位</el-button>-->
      <!--				</div>-->
      <!--			</div>-->

      <div class="al" v-if="statusChecker.payStatus == 1 && statusChecker.cmpyAuthStatus === 1">
        <p>恭喜您已通过平台打款验证</p>
        <el-button type="primary" plain @click="active = active + 1">下一步</el-button>
      </div>

      <div class="pay-btn" v-if="statusChecker.payStatus != 1">
        <el-button
          plain
          class="pb-btn1"
          @click="active = active - 1"
          v-if="active != 1"
          >上一步</el-button
        >
        <el-button type="primary" class="pb-btn2" @click="paySubmit"
          >我已汇款</el-button
        >
      </div>
    </div>

    <!-- 跳转引导 -->
    <div class="enterprise" v-if="active == 3">

        <div class="tabble font14">
          <div class="t-item">
            <div class="to-left">注册公司：</div>
            <div class="to-right">
              <div class="">{{ currentAuthInfo.companyName }}</div>
            </div>
          </div>
          <div class="t-item">
            <div class="to-left">注册手机：</div>
            <div class="to-right">
              <div class="" v-if="currentAuthInfo.phone">{{ currentAuthInfo.phone }}</div>
              <div class="" v-else>{{ '尚未绑定手机号码' }}</div>
            </div>
          </div>
          <div class="t-item">
            <div class="to-left">注册邮箱：</div>
            <div class="to-right">
              <div class="" v-if="currentAuthInfo.mail">{{ currentAuthInfo.mail }}</div>
              <div class="" v-else>{{ '尚未绑定邮箱' }}</div>
            </div>
          </div>
          <div class="t-checker">
            <div class="to-left">打款验证：</div>
            <div class="to-right">
              <div class="y-fail" v-if="statusChecker.payStatus == -1">已拒绝</div>
              <div class="y-fail" v-if="statusChecker.payStatus == 0">审核中</div>
              <div class="y-fail"
                  v-if="statusChecker.payStatus == 1"
                  style="font-weight: bold; color: #409eff">审核通过</div>
            </div>
          </div>
          <div class="t-item" v-if="statusChecker.payStatus == -1">
            <div class="to-left">拒绝原因：</div>
            <div class="to-right">
              <div class="y-fail" style="color: red">
                {{ reason }}
              </div>
            </div>
          </div>
          <div class="t-checker">
            <div class="to-left">企业信息：</div>
            <div class="to-right">
              <div class="y-ing"
                   v-if="statusChecker.cmpyStatus == 0">尚未完善</div>
              <div class="y-success"
                   v-if="statusChecker.cmpyStatus == 1"
                   style="font-weight: bold; color: #409eff">已完善</div>
            </div>
            <el-button type="primary" plain  @click="goCompayInfoPage"
                       v-if="statusChecker.cmpyStatus===0">去完善</el-button>
          </div>
          <div class="t-checker">
            <div class="to-left">用户信息：</div>
            <div class="to-right">
              <div class="y-ing"
                   v-if="statusChecker.cmpyUserStatus == 0">尚未完善</div>
              <div class="y-success"
                   v-if="statusChecker.cmpyUserStatus == 1"
                   style="font-weight: bold; color: #409eff">已完善</div>
            </div>
            <el-button type="primary" plain @click="goCompayUserInfoPage"
                       v-if="statusChecker.cmpyUserStatus===0">去完善</el-button>
          </div>
        </div>
      <div class="al">
        <p>恭喜您已完成账号注册</p>
<!--        <el-button type="primary" plain @click="active = active - 1" v-if="active > 1">上一步</el-button>-->
        <el-button type="primary" plain @click="goJobPush"
                   v-if="statusChecker.cmpyStatus===1 && statusChecker.cmpyUserStatus===1">去发布职位
        </el-button>
      </div>
    </div>

    <!-- 操作成功 -->
    <el-dialog
      :visible.sync="successDialogVisible"
      width="800px"
      center
      :show-close="false"
      :modal-append-to-body="false"
    >
      <div class="updown_box">
        <div class="ub-img">
          <img src="../assets/images/common/gou-chenggong.png" />
        </div>
        <div class="ub-text font22">提交成功，等待平台审核</div>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="companyUserDialogVisible"
      width="800px"
      center
      :show-close="false"
      :modal-append-to-body="false"
    >
      <div class="updown_box">
        <div class="ub-img">
          <img src="../assets/images/common/gou-chenggong.png" />
        </div>
        <div class="ub-text font22">等待公司管理员操作</div>
      </div>
    </el-dialog>

    <el-dialog
      :visible.sync="workDialogVisible"
      width="600px"
      center
      :show-close="false"
      :modal-append-to-body="false"
      class="work-box-out"
    >
      <div class="work-box">
        <span class="closeicon" @click="closeworkDialogbox()">
          <i class="el-icon-error"></i>
        </span>
        <div class="wb-tit font20">公司地址</div>
        <div class="frombox">
          <el-form
            :model="workForm"
            ref="workForm"
            label-width="150px"
            class="demo-ruleForm"
          >
            <el-form-item
              label="街道/写字楼："
              prop="street"
              :rules="[{ required: true, message: '请填写真实地址' }]"
            >
              <el-select
                v-model="tipInput"
                class="tipInput"
                filterable
                remote
                reserve-keyword
                clearable
                placeholder="请输入行政区、街道、写字楼"
                :remote-method="remoteMethod"
                :loading="loading"
                @change="tipChange"
                style="width: 100%"
              >
                <el-option
                  v-for="item in tips"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                  <span style="float: left">{{ item.name }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{
                    item.district
                  }}</span>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="门牌号："
              prop="housenumber"
              :rules="[{ required: true, message: '请填写真实地址' }]"
            >
              <el-input
                type="text"
                style="width: 100%"
                v-model="workForm.housenumber"
                placeholder="请输入行政区、街道、写字楼"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <img
              v-if="!markers.length"
              class="amap-wrapper"
              style="width: 100%"
              src="../assets/images/common/map.png"
            />
            <div class="amap-wrapper" v-else style="width: 100%">
              <el-amap
                class="amap-box"
                :zoom="zoom"
                :center="center"
                :mapStyle="mapStyle"
              >
                <el-amap-marker
                  v-for="(marker, index) in markers"
                  :position="marker.position"
                  :events="marker.events"
                  :vid="index"
                  :icon="marker.icon"
                  v-bind:key="index"
                >
                </el-amap-marker>
                <el-amap-info-window
                  v-if="window"
                  :position="window.position"
                  :visible="window.visible"
                  :content="window.content"
                  :offset="window.offset"
                  :is-custom="true"
                >
                  <div id="info-window">
                    <p>{{ window.address }}</p>
                  </div>
                </el-amap-info-window>
              </el-amap>
            </div>
            <el-form-item>
              <el-button class="qux" @click="workDialogVisible = false"
                >取消</el-button
              >
              <el-button type="primary" @click="submitForm1('workForm')"
                >保存地址</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import location from "../assets/images/size.png";

export default {
  data() {
    return {
      zoom: 14,
      center: [],
      mapStyle: "amap://styles/8b6be8ec497009e17a708205348b899a", //设置地图样式
      markers: [],
      windows: [],
      tipInput: "",
      loading: false,
      map: undefined,
      tips: [],
      window: "",
      workForm: {
        street: "",
        housenumber: "",
      },

      successDialogVisible: false,
      workDialogVisible: false,
      companyUserDialogVisible: false,
      bankNum: "1202021709900253835",
      bankAdress: "工商银行杭州众安支行",
      reason: "",
      active: 1,
      payMoney: parseInt(Math.random() * 100) / 100,
      qyruleForm: {
        name: "",
        username: "",
        code: "",
        address: "",
        businessurl: "",
        lat: "",
        lng: "",
      },
      qyrules: {
        name: [{ required: true, message: "请输入企业名称", trigger: "blur" }],
        username: [
          { required: true, message: "请输入法人姓名", trigger: "blur" },
        ],
        code: [
          {
            required: true,
            message: "请输入统一社会信用代码",
            trigger: "blur",
          },
        ],
        address: [
          { required: true, message: "请设置企业地址", trigger: "blur" },
        ],
        businessurl: [
          { required: true, message: "请上传营业执照", trigger: "blur" },
        ],
      },

      dialogVisible: false,
      currentAuthInfo: {
        companyName: '',
        phone: '',
        mail:'',
      },
      statusChecker: {
        payStatus: -2,       // 账户支付认证
        cmpyAuthStatus: 0,  // 账户公司实名认证
        cmpyStatus: 0,      // 账户公司信息
        cmpyUserStatus: 0,  // 账户用户信息
      },
    };
  },
  created() {
    this.$api.getUserInfo("get").then((res) => {
      if (res.code === 10200) {
        // console.log("$getUserInfo result = ", res);
        let info = res.data;
        if (info.companyUser.companyId > 0) {
          this.currentAuthInfo.companyName = info.company.companyName;
          this.currentAuthInfo.mail = info.companyUser.loginEmail;
          this.currentAuthInfo.phone = info.companyUser.phone;

          if(info.company.companySize === null){ //  || info.company.companyType === null
            this.statusChecker.cmpyStatus = 0;
          } else {
            this.statusChecker.cmpyStatus = 1;
          }
          if(info.companyAuthentication && info.companyAuthentication.shLicensePass !== null){
            this.statusChecker.cmpyAuthStatus = info.companyAuthentication.shLicensePass;
          } else {
            this.statusChecker.cmpyAuthStatus = 0;
          }
          if(info.companyUser.displayName === ""){
            this.statusChecker.cmpyUserStatus = 0;
          } else {
            this.statusChecker.cmpyUserStatus = 1;
          }
          if (info.companyAuthentication.isPayPass === 0) {
            this.qyruleForm.address = info.company.address;
            this.qyruleForm.lat = info.company.lat;
            this.qyruleForm.lng = info.company.lng;
            this.qyruleForm.name = info.companyAuthentication.companyName;
            this.qyruleForm.username = info.companyAuthentication.chargePerson;
            this.qyruleForm.code = info.companyAuthentication.creditCode;

            if (info.companyUser.isAdmin === 1) {
              // this.active = 2;
              this.checkPay();
            } else {
              this.companyUserDialogVisible = true;
            }
          }
          if (info.companyAuthentication.isPayPass === 1) {
            this.active = 2;
            this.checkPay();
          }
        }
        // console.log("$getUserInfo result currentAuthInfo = ", this.currentAuthInfo, ", active = ", this.active);
      }
    });

    // if (this.payMoney == 0) {
    //   this.payMoney == parseInt(Math.random() * 100) / 100;
    // }
  },

  methods: {
    closeworkDialogbox() {
      this.workDialogVisible = false;
    },
    submitForm1(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.qyruleForm.address =
            this.workForm.street + this.workForm.housenumber;
          this.workDialogVisible = false;
        } else {
          return false;
        }
      });
    },
    tipChange(value) {
      for (const tip of this.tips) {
        if (value === tip.id) {
          this.workForm.street = tip.district + tip.name;

          let markers = [];
          let windows = [];
          let basePosition = [tip.location.lng, tip.location.lat];
          this.qyruleForm.lat = tip.location.lat;
          this.qyruleForm.lng = tip.location.lng;
          // let icon = new AMap.Icon({
          // 	image: location,
          // 	size: new AMap.Size(2, 2),
          // 	imageSize: new AMap.Size(2, 2)
          // });
          // content添加相应的内容**********
          markers.push({
            position: basePosition,
            icon: new AMap.Icon({
              image: location,
              size: new AMap.Size(40, 40),
              imageSize: new AMap.Size(40, 40),
            }),
            text: "content1111",
            offset: [0, -50],
          });
          windows.push({
            position: basePosition,
            isCustom: true,
            offset: [10, -40], // 窗体偏移
            visible: true, // 初始是否显示
            address: tip.name,
          });
          this.zoom = 15;
          this.markers = markers;
          this.windows = windows;
          this.center = [tip.location.lng, tip.location.lat];
          this.window = windows[0];
          break;
        }
      }
    },
    remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        const that = this;
        AMap.plugin("AMap.Autocomplete", function () {
          // 实例化Autocomplete
          const autoOptions = {
            city: "全国",
          };
          const autoComplete = new AMap.Autocomplete(autoOptions);
          autoComplete.search(query, function (status, result) {
            // console.log(result);
            // 搜索成功时，result即是对应的匹配数据
            that.tips = result.tips || [];
            that.loading = false;
          });
        });
      } else {
        this.tips = [];
      }
    },

    goJobPush() {
      // this.$router.push({
      //   path: "/induction",
      // });
      // window.location.reload();
      window.location.href = window.location.origin + "/#/induction";
      window.location.reload();
    },
    goCompayInfoPage() {
      window.location.href = window.location.origin + "/#/basicinfo";
      window.location.reload();
    },
    goCompayUserInfoPage() {
      window.location.href = window.location.origin + "/#/personaldata";
      window.location.reload();
    },
    next() {
      if (this.active++ > 2) this.active = 1;
    },
    checkPay() {
      this.$api.checkPay("get").then((res) => {
        if(res.data){
          this.payMoney = res.data.money;
          this.statusChecker.payStatus = res.data.status;
          this.reason = res.data.reason;
        }
        // console.log("checkPay result statusChecker = ", this.statusChecker);
        if(this.statusChecker.payStatus === 1){  // && this.statusChecker.cmpyAuthStatus === 1
          this.active = 3;
        }
        if (this.statusChecker.payStatus < 1) {  // && this.statusChecker.cmpyAuthStatus === -1
          this.active = 1;
        }
      });
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
      this.qyruleForm.businessurl = "";
    },
    handleSuccess(response, file, fileList) {
      console.log(response, file, fileList);
      this.qyruleForm.businessurl = response.data;
    },
    handlePictureCardPreview(file) {
      this.qyruleForm.businessurl = file.url;
      this.dialogVisible = true;
    },
    submitForm(formName) {
      let config = {
        companyName: this.qyruleForm.name,
        chargePerson: this.qyruleForm.username,
        creditCode: this.qyruleForm.code,
        businessLicense: this.qyruleForm.businessurl,
        address: this.qyruleForm.address,
        lat: this.qyruleForm.lat,
        lng: this.qyruleForm.lng,
      };
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // console.log(config);
          this.$api.companyca("post", config).then(() => {
            // console.log(res);
            this.active++;
          });
        } else {
          return false;
        }
      });
    },
    paySubmit() {
      this.$api.paysubmit("post", { money: this.payMoney }).then(() => {
        // console.log(res);
        this.successDialogVisible = true;
        setTimeout(() => {
          this.successDialogVisible = false;
          window.location.reload();
        }, 1000);
      });
    },
    // resetForm(formName) {
    // 	this.$refs[formName].resetFields();
    // }
  },
};
</script>

<style>
.el-aside,
.el-header,
.one {
  display: none !important;
}
</style>
<style lang="less" scoped>
.al {
  display: flex;
  color: #fff;
  padding: 10px 20px;
  justify-content: space-between;
  align-items: center;
  background-color: #e6a23c;
  button {
    border-radius: 30px;
    border: none;
    font-size: 12px;
    color: #d86d25;
    height: 40px;

    &:hover {
      background-color: #fff;
      color: #d86d25;
    }
  }
}

.realname {
  padding: 50px 140px 50px 140px;
  background-color: #ffffff;
  .content-top {
  }
  .enterprise {
    margin-top: 50px;
    padding: 0 150px 0 100px;
  }
  .payment {
    margin-top: 50px;
    padding: 0 86px 0 86px;
  }
}
/deep/ .el-input__inner {
  border-radius: 0px;
}
/deep/ .el-button {
  border-radius: 0px;
}

/deep/ .el-upload--picture-card {
  width: 80px;
  height: 80px;
}
/deep/ .el-upload {
  width: 80px;
  height: 80px;
  line-height: 80px;
}
/deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 80px;
  height: 80px;
  line-height: 80px;
}
/deep/ .el-upload-list--picture-card .el-upload-list__item-thumbnail {
  width: 80px;
  height: 80px;
  line-height: 80px;
}
/deep/ .avatar {
  width: 80px;
  height: 80px;
}
.amap-wrapper {
  width: 500px;
  height: 250px;
  margin-bottom: 20px;
}
.d-btn-orange {
  width: 100%;
  height: 44px;
  // background-color: #00bcff;
  border: none;
  color: #ffffff;
}
.imgts {
  color: #333333;
}
.tabble {
  border: 1px solid #dddddd;
  padding: 0 30px;
  .t-item:nth-of-type(1) {
    border-top: none;
  }
  .t-item {
    padding: 20px 0;
    border-top: 1px solid #dddddd;
    display: flex;
    // align-items: center;
    .to-left {
      color: #666666;
    }

    .to-right {
      color: #111111;
      line-height: 1.5;

      .ts_text {
        color: #666666;
      }

      .y-ing {
        color: #ff4444;
      }

      .y-success {
        color: #00bcff;
      }

      .y-fail {
        color: #666666;
      }
    }
  }
  .t-checker {
    padding: 20px 0;
    border-top: 1px solid #dddddd;
    display: flex;
    justify-content: space-between;
    .to-left {
      color: #666666;
      padding-top: 12px;
    }
    .to-right {
      color: #111111;
      padding-top: 12px;
      line-height: 1.3;
      .ts_text {
        color: #666666;
      }
      .y-ing {
        color: #ff4444;
      }
      .y-success {
        color: #00bcff;
      }
      .y-fail {
        color: #666666;
      }
    }
    .el-button {
      border-radius: 30px;
      border: none;
      font-size: 12px;
      height: 40px;
    }
  }
}
.pay_tishi {
  display: flex;
  margin-top: 10px;
  .p-img {
    margin: 0 8px;
    color: #ff4444;
  }
  .p-cont {
    display: flex;
    flex: 1;
    color: #666666;
    line-height: 1.5;
  }
}
.fail-reason {
  background: #f3f3f3;
  padding: 20px 30px;
  margin-top: 40px;
  color: #999999;
  line-height: 1.5;
}
.succes-text {
  margin-top: 40px;
  background: linear-gradient(#ffa544 0%, #fe9e58 100%);
  padding: 10px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #ffffff;
  .st-btn {
    // width: 94px;

    button {
      border-radius: 50px;
      height: 36px;
      background: linear-gradient(#55baff 0%, #ffdab3 0%, #ffffff 98%);
      color: #d86d25;
      border: none;
      box-shadow: 0px 2px 3px 1px #7f4200;
    }
  }
}
.pay-btn {
  margin-top: 80px;
  button {
    width: 220px;
    height: 40px;
  }
  .pb-btn1 {
    border: 1px solid #00bcff;
    color: #00bcff;
  }
  .pb-btn2 {
    background-color: #00bcff;
  }
}

.updown_box {
  line-height: 3;
  padding: 60px 0;
  position: relative;
  .closeicon {
    position: absolute;
    top: -60px;
    right: 0;
    font-size: 26px;
    color: #f3f3f3;
  }
  .ub-img {
    text-align: center;
    img {
      width: 60px;
      height: 60px;
    }
  }
  .ub-text {
    text-align: center;
    color: #111111;
    font-weight: 600;
  }
  .btnbox {
    text-align: center;
    /deep/ .el-button {
      border-radius: 0px;
      width: 200px;
      margin-top: 50px;
      background: #00bcff;
      border: none;
    }
    .qx {
      background-color: #ffffff;
      color: #00bcff;
      border: 1px solid #00bcff;
      margin-right: 10px;
    }
  }
}
.work-box-out {
  .work-box {
    position: relative;
    .wb-tit {
      padding: 17px 0;
      text-align: center;
      background-color: #f3f3f3;
      color: #000000;
      font-weight: 600;
    }
    /deep/ .el-input__inner {
      border-radius: 0px;
    }
    /deep/ .el-button {
      border-radius: 0px;
    }
    .frombox {
      padding: 50px 60px 30px 60px;
      .demo-ruleForm {
        button {
          width: 160px;
          height: 40px;
          background-color: #00bcff;
        }
        .qux {
          background-color: #fff;
          border: 1px solid #00bcff;
          color: #00bcff;
        }
      }
    }
  }
}
.closeicon {
  position: absolute;
  top: -40px;
  right: 0;
  font-size: 26px;
  color: #f3f3f3;
}
</style>
